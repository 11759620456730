import { Injectable, NgZone } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { StateResetAll } from 'ngxs-reset-plugin';
import { interval, lastValueFrom, Observable, Subject, of } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { PermissionMap } from 'src/app/share/model/permission.model';
import { ApiService } from '../../share/service/api.service';
import { CookieLogin, LoadPermissionMap, Login, LoginSuccess, Logout, SelectedUserChanged, SelectUser } from './user.actions';
import { Client, UserStateModel } from './user.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { deleteCookie, getCookieValue, setCookie } from 'src/app/share/utils/cookie.util';

@State<UserStateModel>({
  name: 'user',
  defaults: {
    loading: false,
    logo: 'assets/default_logo.png',
    token: null,
    userName: null,
    selectedClient: null,
    clients: [],
    clientIds: [],
    permissionMap: {},
    error: null,
  },
})
@Injectable()
export class UserState {
  mistSsoToken = 'mist-sso-token';

  constructor(readonly http: HttpClient, private router: Router, private apiService: ApiService, private store: Store, private zone: NgZone) {}

  private checkSessionUnsubscribe$ = new Subject<void>();

  // Check every 10 min for expired token in cookie. If expired then logout
  checkSession$ = interval(10 * 60 * 1000).pipe(
    tap(() => {
      const token = getCookieValue(this.mistSsoToken);

      if (!token) {
        // Cookie has expired or doesn't exist, update state to null
        this.store.dispatch(new Logout());
      }
    }),
    takeUntil(this.checkSessionUnsubscribe$),
  );

  @Selector([UserState])
  static userAuthToken(state: UserStateModel): string {
    return state.token;
  }

  @Selector([UserState])
  static userName(state: UserStateModel): string {
    return state.userName;
  }

  @Selector([UserState])
  static logo(state: UserStateModel): string {
    return state.logo;
  }

  @Selector([UserState])
  static error(state: UserStateModel): string {
    return state.error;
  }

  @Selector([UserState])
  static clientIdSelected(state: UserStateModel): string | number | null {
    const { selectedClient, clientIds } = state;
    const containsId = clientIds?.find(item => Number(item) === Number(selectedClient));
    if (!containsId && clientIds?.length > 0) {
      return state.clientIds[0];
    }
    return state.selectedClient;
  }

  @Selector([UserState])
  static clients(state: UserStateModel): Client[] {
    return state.clients;
  }

  @Selector([UserState])
  static permissionMap(state: UserStateModel): { [key: string]: string[] } {
    return state.permissionMap;
  }

  @Selector([UserState.userAuthToken])
  static isAuthenticated(token: string): boolean {
    return !!token;
  }

  @Selector([UserState.isAuthenticated, UserState.clients, UserState.permissionMap])
  static permissionLoaded(authenticated: string, clients: Client[], permissionMap: PermissionMap): boolean {
    return authenticated && clients.length && !!permissionMap;
  }

  @Selector([UserState.clients, UserState.clientIdSelected])
  static selectedClient(clients: Client[], id: string | number | null): Client | null {
    const countClients = clients?.length || 0;
    if (countClients === 0) {
      return null;
    } else if (id === null) {
      return clients[0];
    } else {
      return clients.find(item => item.clientId === id);
    }
  }

  static checkUserRole(module: string) {
    return createSelector(
      [UserState.selectedClient, UserState.permissionMap],
      (client: Client | null, permissionMap: PermissionMap) => permissionMap[client?.role]?.filter(permission => permission.includes(module)) || [],
    );
  }

  @Action(Login)
  async login(ctx: StateContext<UserStateModel>, { user, password }): Promise<void> {
    const misttoken = btoa(`${user}:${password}`);
    try {
      const token = await lastValueFrom(
        this.http.get(`${environment.baseUrl}jwt/token`, {
          headers: {
            Authorization: `Basic ${misttoken}`,
          },
          responseType: 'text',
        }),
      );
      setCookie(this.mistSsoToken, token);
      this.store.dispatch(new CookieLogin(token));
    } catch (error) {
      ctx.patchState({ error: 'Error fetching data' });
      return;
    }
  }

  @Action(CookieLogin)
  async CookieLogin(ctx: StateContext<UserStateModel>, { token }): Promise<void> {
    const { loading } = ctx.getState();
    this.checkSession$.subscribe();

    const user = JSON.parse(atob(token.split('.')[1])).sub;

    if (!loading) {
      ctx.patchState({ token, userName: user, loading: true });
      this.store.dispatch(new LoadPermissionMap());
      try {
        const mappedItems = await lastValueFrom(this.apiService.getClients());

        if (mappedItems) {
          ctx.patchState({ clients: mappedItems, clientIds: mappedItems.map(item => item.clientId) });
          this.store.dispatch(new LoginSuccess());
        } else {
          ctx.patchState({ token: null, clientIds: [], userName: null });
        }
      } catch (e) {
        ctx.patchState({ token: null, clientIds: [], userName: null });
      } finally {
        ctx.patchState({ loading: false });
      }
    }
  }

  @Action(Logout)
  logout(): void {
    this.checkSessionUnsubscribe$.next();
    deleteCookie(this.mistSsoToken);
    this.store.dispatch(new StateResetAll());
    this.zone.run(() => this.router.navigate(['/login']));
  }

  @Action(SelectUser)
  selectUser(ctx: StateContext<UserStateModel>, { selectedClient }: SelectUser): UserStateModel {
    const state = ctx.patchState({
      selectedClient,
    });
    this.store.dispatch(new SelectedUserChanged());
    return state;
  }

  @Action(LoadPermissionMap)
  loadPermissionMap(ctx: StateContext<UserStateModel>): Observable<UserStateModel> {
    return this.apiService.permissionMap().pipe(
      map(permissions => ctx.patchState({ permissionMap: permissions })),
      catchError(() => of(ctx.patchState({ permissionMap: {} }))),
    );
  }
}
