<div class="login-wrapper">
  <div class="login">
    <div id="logo-home">
      <img class="logo_main" src="assets/morningstar.svg" alt="Morningstar Logo"/>
    </div>
    <h1 id="nav_heading">Welcome to Morningstar Index Portal UI</h1>
    <form style="width: 570px; margin: 0 auto;" class="content-wrapper">
      <mat-form-field class="calendar-base-field">
        <mat-label>User name</mat-label>
        <input matInput #user name="name" placeholder="Type your username" (keyup.enter)="callLogin"/>
      </mat-form-field>
      <mat-form-field class="calendar-base-field">
        <mat-label>Password</mat-label>
        <input matInput #password name="name" type="password" placeholder="Type your password" (keyup.enter)="callLogin"/>
      </mat-form-field>
      <div class="button-row">
        <span class="mds-form__microcopy" id="email-microcopy">
          Use the email address associated with your Morningstar account.
        </span>
        <button (click)="callLogin(user.value, password.value)"
            class="login-button"
            color="primary"
            [disabled]="!user.value || !password.value"
            mat-stroked-button>
            Login
        </button>
      </div>
      <p *ngIf="errors$ | async" style="color: red;">Bad login information. Message: {{errors$ | async}}</p>
    </form>
  </div>
</div>
