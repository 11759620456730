/**
 * Get Cookie Value from Cookie Name
 *
 * @param name cookie name
 */
export function getCookieValue(name: string): string | null {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
}

export function setCookie(cname, cvalue) {
  const tokenexpires = JSON.parse(atob(cvalue.split('.')[1])).exp;
  const expires = new Date(tokenexpires * 1000).toUTCString();
  document.cookie = cname + '=' + cvalue + '; expires=' + expires + '; path=/';
}

export function deleteCookie(cname) {
  document.cookie = cname + '= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
}
