import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngxs/store';
import { Dayjs, utc } from 'dayjs';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ExecutorAPIModel, ExecutorStatement } from 'src/app/executor/state/executor.model';

import { rxCatchError } from '../../state/notifications/notifications.helper';
import { Client } from '../../state/user/user.model';
import { CalcTplusChangesModel } from '../model/calc-tplus-changes.model';
import { CalculationResponseWrapper } from '../model/calculation-response-wrapper.model';
import { CalendarTypeModel } from '../model/calendar-type.model';
import { CalendarModel } from '../model/calendar.model';
import { CalendarDayModel } from '../model/calendarday.model';
import { CalendarDetailsModel } from '../model/calendarDetails.model';
import { CorporateActionBaseModel } from '../model/corporate-action-base.model';
import { CorporateActionIdModel } from '../model/corporate-action-id.model';
import {
  CorporateActionApplySequenceModel,
  CorporateActionDeleteModel,
  CorporateActionModel,
  CorporateActionStatusChangeModelWithMatchingStatus,
  CorporateActionTransferModel,
} from '../model/corporate-action.model';
import { CorporateActionsBaseOverrideModel, CorporateActionsOverrideIdModel, CorporateActionsOverrideModel, CorporateActionsOverrideStoreResultModel } from '../model/corporate-actions-override.model';
import { CorporateActionsReviewChangeModel } from '../model/corporate-actions-review-change.model';
import { CurrencyModel } from '../model/currency.model';
import { CustomCorporateActionApplyModel } from '../model/custom-corporate-action-apply.model';
import { IndexActionModel } from '../model/index-action.model';
import { IndexConfigurationModel } from '../model/index-configuration.model';
import { IndexStateModel } from '../model/index-state.model';
import { MasterIndexModel } from '../model/master-index.model';
import { MatchingStatusModel } from '../model/matching-status.model';
import { PermissionMap } from '../model/permission.model';
import { PriceAPIModel, SnapPriceResponse } from '../model/price.model';
import { ProviderModel, ProviderQueryModel } from '../model/provider.model';
import { RealtimeCboeData } from '../model/realtime-cboe.model';
import { RealtimeChannelModel } from '../model/realtime-channel.model';
import { RealtimeIndexModel } from '../model/realtime-index.model';
import { ReportDistributeModel } from '../model/report-distribute.model';
import { ReportStatusModel } from '../model/report-status.model';
import { ReturnTypeModel } from '../model/return-type.model';
import { RevalidationResult } from '../model/revalidation-result.model';
import { SecurityExchangeModel, SecurityListingModel } from '../model/security-listing.model';
import { SecurityListing, SecurityModel, SecurityType } from '../model/security.model';
import { StockExchangeModel } from '../model/stock-exchange.model';
import { TaxRateCharacteristicsModel } from '../model/tax-rate-characteristics.model';
import { VariationCalculationWithConstituentsModel } from '../model/variation-calculation-constituents.model';
import { VariationCalculationModel } from '../model/variation-calculation.model';
import { VariationDetailModel } from '../model/variation-detail.model';
import { VariationEodModel } from '../model/variation-eod.model';
import { VariationRestatementDTOModel } from '../model/variation-restatement.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(protected readonly http: HttpClient, protected readonly store: Store) {}

  /**
   * Load list of all master index
   */
  getMasterIndices(): Observable<MasterIndexModel[]> {
    return this.http.get<MasterIndexModel[]>('/index');
  }

  /**
   * Get all stock exchanges
   */
  getStockExchanges(): Observable<StockExchangeModel[]> {
    return this.http.get<StockExchangeModel[]>('/stockexchange');
  }

  /**
   * Find client names by id
   *
   * @param clients Client ids to find
   */
  getClients(): Observable<Client[]> {
    return this.http.get<Client[]>('/client');
  }

  /**
   * Get a list of roles and their corresponding permissions
   */
  permissionMap(): Observable<PermissionMap> {
    return this.http.get<PermissionMap>('/permissions');
  }

  /**
   * Load EOD variation list
   */
  variationEOD(): Observable<VariationEodModel[]> {
    return this.http.get<VariationEodModel[]>('/dataview/index/variation/open-eod');
  }

  /**
   * Load variation detail
   *
   * @param masterdataId Index ID
   * @param variationId Variation ID
   */
  variationDetail(masterdataId: number, variationId: number): Observable<VariationDetailModel> {
    return this.http.get<VariationDetailModel>(`/dataview/index/${masterdataId}/variation/${variationId}`).pipe(
      map(data => {
        return {
          ...data,
          masterdataId,
          variationId,
        };
      }),
    );
  }

  /**
   * Get constituents for given index variation
   *
   * @param indexId Master data ID
   * @param variationId Variation ID
   * @param currency Calculation currency
   * @param calculation Calculation name
   * @param localDate Calculation date
   * @param returnType Calculation return type
   */
  variationCalculationConstituents(
    indexId: number,
    variationId: number,
    currency: string,
    calculation = 'Close',
    localDate: string = null,
    returnType: string = null,
  ): Observable<VariationCalculationWithConstituentsModel> {
    let params = new HttpParams();
    if (localDate) {
      params = params.set('localDate', localDate);
    }
    if (returnType) {
      params = params.set('returnType', returnType);
    }

    return this.http.get<VariationCalculationWithConstituentsModel>(`/dataview/index/${indexId}/variation/${variationId}/currency/${currency}/calculation/${calculation}`, {
      params,
    });
  }

  /**
   * List all calculations
   *
   * @param indexId Index ID
   * @param variationId Variation ID
   * @param currency Calculation currency
   */
  variationCalculation(indexId: number, variationId: number, currency: string): Observable<VariationCalculationModel[]> {
    return this.http.get<VariationCalculationModel[]>(`/dataview/index/${indexId}/variation/${variationId}/currency/${currency}/calculations`);
  }

  /**
   * find securities
   */
  findSecurities(): Observable<SecurityModel[]> {
    return this.http.get<SecurityModel[]>('/security');
  }

  /**
   * Get type's of securities
   *
   */
  getSecurityTypes(): Observable<SecurityType[]> {
    return this.http.get<SecurityType[]>('/security/type');
  }

  /**
   * Get supported currencies
   */
  currencies(): Observable<CurrencyModel[]> {
    return this.http.get<CurrencyModel[]>('/currency');
  }

  /**
   * Get currencies with available fx rates
   */
  providedCurrencies(): Observable<CurrencyModel[]> {
    return this.http.get<CurrencyModel[]>('/currency/fx/available');
  }

  /**
   * Get tax rate characteristics
   */
  taxRateSetCharacteristics(): Observable<TaxRateCharacteristicsModel[]> {
    return this.http.get<TaxRateCharacteristicsModel[]>('/country/characteristic/set');
  }

  /**
   * Get Corporate Actions by Type
   *
   * @param providerType Corporate Action Type
   * @param fromDate Query Date from
   * @param toDate Query Date to
   */
  corporateActionsByProvider(providerType: string, fromDate?: Date | Dayjs, toDate?: Date | Dayjs): Observable<CorporateActionModel[]> {
    let params = new HttpParams();
    if (fromDate) {
      params = params.set('from', utc(fromDate).format('YYYY-MM-DD'));
    }
    if (toDate) {
      params = params.set('to', utc(toDate).format('YYYY-MM-DD'));
    }
    return this.http.get<CorporateActionModel[]>(`/corporate-actions/overview/${providerType}/withindexmembership`, { params });
  }

  /**
   * Create corporate action client copies
   *
   * @param ids Corporate Actions to transfer
   */
  transferCorporateActions(ids: CorporateActionIdModel[]): Observable<CorporateActionTransferModel[]> {
    return this.http.post<CorporateActionTransferModel[]>('/corporate-actions/custom', ids);
  }

  /**
   * Trigger Golden copy
   */
  triggerGoldenCopy(): Observable<unknown> {
    return this.http.get<unknown>('/report/corporate-actions-golden-copy/variation/0/job/1/offset/6', { headers: { 'x-override-clientId': '1' } });
  }

  /**
   * Get Corporate Action override by provider and eventId
   *
   * @param from Limit from - Date
   * @param to Limit to - Date
   */
  corporateActionsOverrides(from?: Date | Dayjs, to?: Date | Dayjs): Observable<CorporateActionsBaseOverrideModel[]> {
    let params = new HttpParams();
    const dateFormat = 'YYYY-MM-DD';
    if (from) {
      params = params.set('from', utc(from).format(dateFormat));
    }
    if (to) {
      params = params.set('to', utc(to).format(dateFormat));
    }
    return this.http.get<CorporateActionsOverrideModel[]>('/corporate-action/override', { params });
  }

  /**
   * Create corporate action overrides
   *
   * @param items Corporate actions to create
   */
  createCorporateActionOverrides(items: CorporateActionsBaseOverrideModel[]): Observable<CorporateActionsOverrideStoreResultModel[]> {
    return this.http.post<CorporateActionsOverrideStoreResultModel[]>('/corporate-action/override', items);
  }

  /**
   * Update corporate action override
   *
   * @param item Corporate action to update
   */
  updateCorporateActionOverride(item: CorporateActionsBaseOverrideModel): Observable<CorporateActionsOverrideStoreResultModel> {
    return this.http.put<CorporateActionsOverrideStoreResultModel>(`/corporate-action/override/actionId/${item.actionId}/variationId/${item.variationId}`, item);
  }

  /**
   * Delete corporate action override
   *
   * @param item Corporate action to delete
   */
  deleteCorporateActionOverrides(item: CorporateActionsOverrideIdModel): Observable<CorporateActionsOverrideStoreResultModel> {
    let params = new HttpParams();
    params = params.set('returnTypeId', item.returnTypeId);

    return this.http.delete<CorporateActionsOverrideStoreResultModel>(`/corporate-action/override/actionId/${item.actionId}/variationId/${item.variationId}`, { params });
  }

  /**
   * Delete a selected custom corporate action
   *
   * @param providerId Provider ID
   * @param matchingStatus existing matchingStatus of CA
   * @param providerEventId Provider Event ID
   */
  deleteSelectedCustomCorporateAction(providerId: number, providerEventId: string, matchingStatus: MatchingStatusModel): Observable<CorporateActionDeleteModel> {
    const requestOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: matchingStatus,
    };
    return this.http.delete<CorporateActionDeleteModel>(`/corporate-actions/${providerId}/${providerEventId}`, requestOptions);
  }

  /**
   * Get corporate action details
   *
   * @param providerId Provider ID
   * @param providerEventId Provider Event ID
   */
  getCorporateActionDetails(providerId: number, providerEventId: string): Observable<CorporateActionBaseModel> {
    return this.http.get<CorporateActionBaseModel>(`/corporate-actions/${providerId}/${providerEventId}`).pipe(rxCatchError(this.store, 'Get corporate action details failed.'));
  }

  /**
   * Created custom corporate action
   *
   * @param item Corporate Action to create
   */
  corporateActionCustomCreate(item: CorporateActionBaseModel, skipValidation = false): Observable<CustomCorporateActionApplyModel> {
    // bypass validation flag is introduce to skip validation of original ex date check if its true.
    return this.http.post<CustomCorporateActionApplyModel>(`/corporate-actions?bypassValidation=${skipValidation}`, item).pipe(
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((error: HttpErrorResponse) => {
        if (error.status < 500) {
          return of(error.error);
        }

        return throwError(() => error);
      }),
    );
  }

  /**
   * Update existing custom corporate action
   *
   * @param item Corporate Action to update
   */
  corporateActionCustomUpdate(item: CorporateActionBaseModel): Observable<CustomCorporateActionApplyModel> {
    return this.http.put<CustomCorporateActionApplyModel>('/corporate-actions', item);
  }

  /**
   * Apply status to custom corporate action
   *
   * @param obj confirmed corporate action
   * @param matchingStatus matchingStatus for each changed CA
   * @param status of corporate action
   */
  applyStatus(obj: CorporateActionIdModel[], matchingStatus: MatchingStatusModel[], status: string): Observable<CustomCorporateActionApplyModel[]> {
    const requestBody: CorporateActionStatusChangeModelWithMatchingStatus = { corporateActionIdList: obj, matchingStatus };
    return this.http.put<CustomCorporateActionApplyModel[]>(`/corporate-actions/status/${status}`, requestBody);
  }

  /**
   * Apply sequence to custom corporate action
   *
   * @param requestBody corporate action sequence apply model
   */
  applySequence(requestBody: CorporateActionApplySequenceModel[]): Observable<CustomCorporateActionApplyModel[]> {
    return this.http.patch<CustomCorporateActionApplyModel[]>('/corporate-actions/sequence', requestBody);
  }

  /**
   * Changing 'review' flag of given corporate action
   *
   * @param item Corporate Action to change reviewed flag
   */
  corporateActionChangeReviewed(item: CorporateActionModel): Observable<CorporateActionsReviewChangeModel> {
    return this.http.post<CorporateActionsReviewChangeModel>(`/corporate-actions/provider/${item.providerId}/event/${item.providerEventId}/review`, { reviewed: !item.reviewed });
  }

  /**
   * Get all corporate action index actions
   *
   * @param fromDate Limit request data by from-date
   * @param toDate Limit request data by to-date
   */
  getIndexActions(fromDate?: Date | Dayjs, toDate?: Date | Dayjs): Observable<IndexActionModel[]> {
    const validResults = ['newConstituent', 'delConstituent', 'shareChange'];
    let params = new HttpParams();
    const dateFormat = 'YYYY-MM-DD';
    if (fromDate) {
      params = params.set('from', utc(fromDate).format(dateFormat));
    }
    if (toDate) {
      params = params.set('to', utc(toDate).format(dateFormat));
    }
    return this.http.get<IndexActionModel[]>('/index-action', { params }).pipe(map(items => items.filter(item => validResults.includes(item.actionName))));
  }

  /**
   * Update existing index action
   *
   * @param actionId Index Action event ID
   * @param body Index action content
   */
  updateIndexAction(actionId: number, body: IndexActionModel): Observable<IndexActionModel> {
    return this.http.put<IndexActionModel>(`/index-action/${actionId}`, body);
  }

  /**
   * Create new index action
   *
   * @param masterdataId Index Id
   * @param variationId Index variation Id
   * @param body Index Action content
   */
  createIndexAction(masterdataId: number, variationId: number, body: IndexActionModel): Observable<IndexActionModel[]> {
    let params = new HttpParams();
    params = params.set('masterdataId', String(masterdataId)).set('variationId', String(variationId));
    return this.http.post<IndexActionModel[]>('/index-action', body, { params });
  }

  /**
   * Delete index action with given id
   *
   * @param id Variation ID
   */
  deleteIndexAction(id: number): Observable<unknown> {
    return this.http.delete<unknown>(`/index-action/${id}`);
  }

  /**
   * Search for security listing by type and identifier
   *
   * @param type Search type (ISIN | RIC)
   * @param identifier
   */
  searchSecurityListing(type: 'ISIN' | 'RIC', identifier: string): Observable<SecurityListingModel> {
    return this.http.get<SecurityListingModel>(`/security/search/${type}/${identifier}`).pipe(catchError(() => of(null)));
  }

  /**
   * Trigger distribute latest report
   *
   * @param level Distribution level
   * @param body Reports
   */
  distributeLatestReport(level: string, body: ReportDistributeModel): Observable<unknown> {
    let params = new HttpParams();
    params = params.set('type', 'distribute');
    if (level) {
      params = params.set('level', level);
    }
    return this.http.post<unknown>('/report/bulk-reports', body, { params }).pipe(rxCatchError(this.store, 'Failed to create reports'));
  }

  /**
   * Trigger download latest report
   *
   * @param body Reports
   */
  downloadLatestReport(body: ReportDistributeModel): Observable<Blob> {
    const params = new HttpParams().set('type', 'download');
    return this.http
      .post<Blob>('/report/bulk-reports', body, {
        responseType: 'arraybuffer' as 'json',
        params,
      })
      .pipe(rxCatchError(this.store, 'Failed to download report(s)'));
  }

  /**
   * Trigger distribute pro forma file
   *
   * @param variationId Index variation id
   * @param date File date
   */
  distributeProformaReport(variationId: number, date: string): Observable<unknown> {
    let params = new HttpParams();
    params = params.set('type', 'distribute');
    params = params.set('date', date);
    return this.http.get(`/report/${variationId}/pro-forma`, { params });
  }

  /**
   * Trigger download pro forma file
   *
   * @param variationId Index variation id
   * @param date File date
   */
  downloadProformaReport(variationId: number, date: string): Observable<string> {
    let params = new HttpParams();
    params = params.set('type', 'download');
    params = params.set('date', date);
    return this.http.get<string>(`/report/${variationId}/pro-forma`, { params, responseType: 'text' as 'json' });
  }

  /**
   * Get report status for a client id
   */
  getGeneratedReportByClientId(): Observable<ReportStatusModel[]> {
    return this.http.get<ReportStatusModel[]>('/report/publish/status');
  }

  /**
   * Load variation detail EOD
   *
   * @param indexId Index ID
   * @param variationId Variation ID
   * @param date File date
   * @param dateFrom data range: from
   * @param historical: to enable historical
   * @param publish: Publish
   * @param calculationMethodologyType: Calculation Methodology Type
   */
  eodRecalculateValue(
    indexId: number,
    variationId: number,
    dateFrom: string,
    date: string,
    historical: boolean,
    publish: boolean,
    calculationMethodologyType: string,
  ): Observable<CalculationResponseWrapper> {
    let params = new HttpParams();
    if (dateFrom) {
      params = params.set('calculateFromDate', dateFrom);
    }

    if (historical) {
      params = params.set('historical', 'true');
    }

    if (publish) {
      params = params.set('publish', 'true');
    }

    if (!calculationMethodologyType) {
      calculationMethodologyType = 'EOD';
    }
    params = params.set('calculationMethodologyType', calculationMethodologyType);
    return this.http.post<CalculationResponseWrapper>(`/index/${indexId}/variation/${variationId}/timespan/${date}/work`, {}, { params });
  }
  
  /**
   * Load variation detail T-PLUS
   *
   * @param indexId Index ID
   * @param variationId Variation ID
   * @param date File date
   * @param dateFrom data range: from
   * @param historical: to enable historical
   */
  tplusRecalculateValue(indexId: number, variationId: number, dateFrom: string, date: string, historical: boolean): Observable<CalculationResponseWrapper> {
    let params = new HttpParams();
    if (dateFrom) {
      params = params.set('calculateFromDate', dateFrom);
    }
    if (historical) {
      params = params.set('historical', 'true');
    }
    params = params.set('calculationMethodologyType', 'T_PLUS');
    return this.http.post<CalculationResponseWrapper>(`/index/${indexId}/variation/${variationId}/timespan/${date}/work`, {}, { params });
  }

  /**
   * Load TPlus changes for given date range
   *
   * @param indexId Index ID
   * @param variationId Variation ID
   * @param fromDate Limit from-date
   * @param toDate Limit to-date
   */
  getTPlusChanges(indexId: number, variationId: number, fromDate: string, toDate: string): Observable<CalcTplusChangesModel[]> {
    let params = new HttpParams();
    params = params.set('fromDate', fromDate);
    params = params.set('toDate', toDate);
    return this.http.get<CalcTplusChangesModel[]>(`/index/${indexId}/variation/${variationId}/tpluschanges/TPlus`, { params });
  }

  /**
   * Get tplus changes for date and calculation name
   *
   * @param indexId Index ID
   * @param variationId Variation ID
   * @param date Calculation date
   */
  getTPlusChangesAsCSV(indexId: number, variationId: number, date: string): Observable<string> {
    let params = new HttpParams();
    params = params.set('date', date);
    return this.http.get<string>(`/index/${indexId}/variation/${variationId}/tpluschanges/TPlus/csv`, {
      responseType: 'text' as 'json',
      params,
    });
  }

  /**
   * Get tplus changes for date and calculation name
   *
   * @param indexId Index ID
   * @param variationId Variation ID
   * @param selectedCurrency Selected currency
   * @param calculationName Calculation name
   * @param calculationDate Calculation date
   */
  getCalculationVersionAsCSV(indexId: number, variationId: number, selectedCurrency: string, calculationName: string, calculationDate: string): Observable<string> {
    let params = new HttpParams();
    params = params.set('date', calculationDate);
    return this.http.get<string>(`/index/${indexId}/variation/${variationId}/currency/${selectedCurrency}/calculation/${calculationName}/csv?date=${calculationDate}`, {
      responseType: 'text' as 'json',
      params,
    });
  }

  /**
   * Get price list by RIC
   *
   * @param currency Security currency
   * @param date Price date
   * @param ric Constituent's RIC
   * @param fxProviderId FX provider id
   * @param fxProviderQueryId FX provider query id
   */
  getPricingByListing(currency: string, date: string, ric: string[], fxProviderId: number, fxProviderQueryId: number): Observable<SecurityExchangeModel[]> {
    let params = new HttpParams();
    params = params.set('currency', currency);
    params = params.set('localDate', date);
    params = ric.reduce((acc, item) => acc.append('ric', item), params);
    params = params.set('fxProviderId', fxProviderId);
    params = params.set('fxProviderQueryId', fxProviderQueryId);
    return this.http.get<SecurityExchangeModel[]>('/security/exchanges', { params });
  }

  /**
   * Add new security
   *
   * @param security Security to add
   */
  addNewSecurity(security: SecurityModel): Observable<SecurityModel> {
    return this.http.post<SecurityModel>('/security', security).pipe(rxCatchError(this.store, 'Failed to add new security.'));
  }

  /**
   * Edit existing security
   *
   * @param security Security to edit
   */
  editSecurity(security: SecurityModel): Observable<SecurityModel> {
    return this.http.put<SecurityModel>(`/security/${security.securityId}`, security).pipe(rxCatchError(this.store, 'Failed to edit security.'));
  }

  /**
   * delete security
   *
   * @param securityId Id of a security
   */
  deleteSecurity(securityId: number): Observable<unknown> {
    return this.http.delete<SecurityModel>(`/security/${securityId}`).pipe(rxCatchError(this.store, 'Failed to delete a security.'));
  }

  /**
   * Gets a list of all available indices. Each index contains its variant/config combinations with some additional data.
   */
  getVariationRestatements(): Observable<VariationRestatementDTOModel[]> {
    return this.http.get<VariationRestatementDTOModel[]>('/dataview/index/variations');
  }

  /**
   * Upload rebalance data
   *
   * @param indexId Index Id
   * @param variationId Variation Id
   * @param configId Config Id
   * @param file Rebalance File
   */
  uploadRebalanceFile(indexId: number, variationId: number, configId: number, file: File): Observable<RevalidationResult[]> {
    const data = new FormData();
    data.append('file', file);
    return this.http.post<RevalidationResult>(`/index-action/${indexId}/variation/${variationId}/config/${configId}/rebalanceData`, data).pipe(map(result => [result]));
  }

  /**
   * Revalidate variation
   *
   * @param indexId Index Id
   * @param variationId Variation Id
   */
  revalidateVariation(indexId: number, variationId: number): Observable<RevalidationResult[]> {
    return this.http.post<RevalidationResult[]>(`/index-action/${indexId}/variation/${variationId}/revalidate`, {}).pipe(rxCatchError(this.store, 'Failed to revalidate variation.'));
  }

  /**
   * Load index configurations
   */
  loadConfigurations(): Observable<IndexConfigurationModel[]> {
    return this.http.get<IndexConfigurationModel[]>('/index/config').pipe(rxCatchError(this.store, 'Failed to load configurations'));
  }

  /**
   * Delete existing configuration
   *
   * @param indexId Index Id
   * @param configId Configuration Id
   */
  deleteConfiguration(indexId: number, configId: number): Observable<void> {
    return this.http.delete<void>(`/index/${indexId}/config/${configId}`).pipe(rxCatchError(this.store, 'Failed to delete configuration.'));
  }

  /**
   * Update existing index configuration
   *
   * @param indexId Index Id
   * @param configId Configuration Id
   * @param body Configuration to update
   */
  updateConfiguration(indexId: number, configId: number, body: IndexConfigurationModel): Observable<IndexConfigurationModel> {
    return this.http.put<IndexConfigurationModel>(`/index/${indexId}/config/${configId}`, body).pipe(rxCatchError(this.store, 'Failed to update existing configuration.'));
  }

  /**
   * Create new index configuration
   *
   * @param indexId Related index ID
   * @param body Configuration to create
   */
  createConfiguration(indexId: number, body: IndexConfigurationModel): Observable<IndexConfigurationModel> {
    return this.http.post<IndexConfigurationModel>(`/index/${indexId}/config`, body).pipe(rxCatchError(this.store, 'Failed to create new configuration.'));
  }

  /**
   * Load providers
   */
  loadProviders(): Observable<ProviderModel[]> {
    return this.http.get<ProviderModel[]>('/provider');
  }

  /**
   * Load providers
   */
  providerQueries(): Observable<ProviderQueryModel[]> {
    const params = new HttpParams().set('getAllQueries', true);
    return this.http.get<ProviderQueryModel[]>('/provider/query', { params }).pipe(rxCatchError(this.store, 'Failed to query providers.'));
  }

  /**
   * Get listings for given security id
   *
   * @param securityId Security ID
   */
  getListingForSecurity(securityId: number | string): Observable<SecurityListing[]> {
    return this.http.get<SecurityListing[]>(`/listing/security/${securityId}`).pipe(rxCatchError(this.store, 'Failed to get security listing.'));
  }

  /**
   * Create security listing
   *
   * @param item listing to create
   */
  createSecurityListings(item: SecurityListing): Observable<SecurityListing> {
    return this.http.post<SecurityListing>('/listing', item).pipe(rxCatchError(this.store, `Failed to create security listing ${item.listingId}.`));
  }

  /**
   * Update existing security listing
   *
   * @param _listingId Listing Id
   * @param item Listing to create
   */
  editSecurityListings(_listingId: number, item: SecurityListing): Observable<SecurityListing> {
    return this.http.put<SecurityListing>(`/listing/${item.listingId}`, item).pipe(rxCatchError(this.store, `Failed to update security listing ${item.listingId}.`));
  }

  /**
   * Delete a selected security listing
   *
   * @param listingId Security listing ID
   */
  deleteSecurityListings(listingId: number): Observable<SecurityListing> {
    return this.http.delete<SecurityListing>(`/listing/${listingId}`).pipe(rxCatchError(this.store, `Failed to delete security listing ${listingId}.`));
  }

  /**
   * Get list of available realtime channels
   */
  getRealtimeChannels(): Observable<RealtimeChannelModel[]> {
    return this.http.get<RealtimeChannelModel[]>('/cboe/channels');
  }

  /**
   * Get list of mapped indices for selected channels
   *
   * @param channels Selected channels
   */
  getRealtimeIndices(channels: string[]): Observable<RealtimeIndexModel[]> {
    let params = new HttpParams();
    params = params.set('channels', channels.join());
    return this.http.get<RealtimeIndexModel[]>('/cboe/mapped-indices', { params });
  }

  /**
   * Get CBOE data from index-backend
   */
  getCboeData(masterId: number, variationId: number): Observable<RealtimeCboeData> {
    return this.http.get<RealtimeCboeData>(`/dataview/cboe/masterdataId/${masterId}/variation/${variationId}`);
  }

  /**
   * Create a new index
   *
   * @param index Index to create
   */
  createRealtimeIndex(index: RealtimeIndexModel): Observable<RealtimeIndexModel> {
    return this.http.post<RealtimeIndexModel>('/cboe/index', index);
  }

  /**
   * Trigger price snapshot
   */
  snapRefinitivRTPrices(): Observable<SnapPriceResponse> {
    return this.http.post<SnapPriceResponse>('/price/importSnapPrices', {});
  }

  /**
   * Update an existing index
   *
   * @param index Index to be updated
   * @param symbol Index symbol
   */
  updateRealtimeIndex(index: RealtimeIndexModel, symbol: string): Observable<RealtimeIndexModel> {
    return this.http.put<RealtimeIndexModel>(`/cboe/index/${symbol}`, index);
  }

  /**
   * Get list of all available currency provider queries
   */
  getCurrencyProviderQueries(): Observable<ProviderQueryModel[]> {
    let params = new HttpParams();
    params = params.set('priceType', 'CLOSE');
    return this.http.get<ProviderQueryModel[]>('/provider/query/FX', { params });
  }

  /**
   * Load listing values
   */
  loadListingValues(fromDate: Date | Dayjs, toDate: Date | Dayjs, identifier?: string, identifierType?: string): Observable<PriceAPIModel[]> {
    let params = new HttpParams();
    const dateFormat = 'YYYY-MM-DD';
    if (fromDate) {
      params = params.set('startingDate', utc(fromDate).format(dateFormat));
    }
    if (toDate) {
      params = params.set('endingDate', utc(toDate).format(dateFormat));
    }
    if (identifier && identifierType !== 'none') {
      params = params.set('valueId', identifier);
    }
    if (identifierType) {
      params = params.set('typeId', identifierType);
    }
    return this.http.get<PriceAPIModel[]>('/listing/value', { params });
  }

  /**
   * Delete existing listing value
   *
   * @param body Listing values to delete
   */
  deleteListingValue(body: PriceAPIModel): Observable<PriceAPIModel> {
    return this.http.delete<PriceAPIModel>('/listing/listingValueWithIdentifiers', { body });
  }

  /**
   * Store new prices
   *
   * @param body Prices to store
   */
  createNewPrices(body: PriceAPIModel[]): Observable<PriceAPIModel[]> {
    return this.http.post<PriceAPIModel[]>('/listing/prices', body);
  }

  /**
   * Creates  listing value
   *
   * @param body Listing value to create
   */
  createListingValue(body: PriceAPIModel): Observable<PriceAPIModel> {
    return this.http.post<PriceAPIModel>('/listing/listingValueWithIdentifiers', body);
  }

  /**
   * Update existing listing value
   *
   * @param body Listing value to update
   */
  updateListingValue(body: PriceAPIModel): Observable<PriceAPIModel> {
    return this.http.put<PriceAPIModel>('/listing/listingValueWithIdentifiers', body);
  }

  /**
   * Get list of all available calendars
   */
  getCalendars(): Observable<CalendarModel[]> {
    return this.http.get<CalendarModel[]>('/calendar');
  }

  /**
   * Creates a new calendar
   *
   * @param calendar New calendar to create
   */
  createCalendar(calendar: CalendarModel): Observable<CalendarModel> {
    return this.http.post<CalendarModel>('/calendar', calendar);
  }

  /**
   * Updates an existing calendar
   *
   * @param id Calendar ID
   * @param calendar The updated calendar
   */
  updateCalendar(id: string | number, calendar: CalendarModel): Observable<CalendarModel> {
    return this.http.put<CalendarModel>(`/calendar/${id}`, calendar);
  }

  /**
   * Deletes an existing calendar
   *
   * @param id Id of the calendar to delete
   */
  deleteCalendar(id: number): Observable<unknown> {
    return this.http.delete<unknown>(`/calendar/${id}`);
  }

  /**
   * Get list of calendar types
   */
  getCalendarType(): Observable<CalendarTypeModel[]> {
    return this.http.get<CalendarTypeModel[]>('/calendar/type');
  }

  /**
   * Get list of calendar types
   */
  getCalendarDayType(): Observable<CalendarTypeModel[]> {
    return this.http.get<CalendarTypeModel[]>('/calendarday/type');
  }

  /**
   * Get details of a single calendar
   *
   * @param id Id of the wanted calendar
   */
  getCalendarDetails(id: number): Observable<CalendarDetailsModel> {
    return this.http.get<CalendarDetailsModel>(`/calendar/details/${id}`);
  }

  /**
   * Get calendar-days
   *
   * @param calendarId Id of the calendar to get the days of
   */
  getCalendarDays(calendarId: number): Observable<CalendarDayModel[]> {
    return this.http.get<CalendarDayModel[]>(`/calendarday/${calendarId}`);
  }

  /**
   * Update a calendar day
   *
   * @param calendarId Origin calendar id
   * @param type Origin calendar type
   * @param date Origin calendar date
   * @param calendar Updated calendar item
   */
  updateCalendarDay(calendarId: number, type: string, date: string, calendar: CalendarDayModel): Observable<CalendarDayModel> {
    return this.http.put<CalendarDayModel>(`/calendarday/${calendarId}/${type}/${date}`, calendar);
  }

  /**
   * Creates a new calendar day
   *
   * @param calendarDay Day to create
   */
  createCalendarDay(calendarDay: CalendarDayModel): Observable<CalendarDayModel> {
    return this.http.post<CalendarDayModel>('/calendarday', calendarDay);
  }

  /**
   * Create new calendar days in a bulk
   *
   * @param calendarDays Calendar days to create
   */
  createCalendarDays(calendarDays: CalendarDayModel[]): Observable<CalendarDayModel[]> {
    return this.http.post<CalendarDayModel[]>('/calendarday/days', calendarDays);
  }

  /**
   * Deletes a calendar day in a calendar
   *
   * @param calendarId Id of the calendar in which the day should be deleted
   * @param type Type of the day to delete
   * @param date Date of the day to delete
   */
  deleteCalendarDay(calendarId: number, type: string, date: string): Observable<unknown> {
    return this.http.delete<unknown>(`/calendarday/day/${calendarId}/${type}/${date}`);
  }

  /**
   * Deletes all days in an existing calendar
   *
   * @param calendarId Id of the calendar which should be emptied
   */
  emptyCalendarDays(calendarId: number): Observable<void> {
    return this.http.delete<void>(`/calendarday/days/${calendarId}`);
  }

  /**
   * Retrieve a list of timezones to use in the portal
   *
   * @returns List of all available timezones
   */
  getTimezones(): Observable<string[]> {
    return this.http.get<string[]>('/timezone');
  }

  /**
   * Retrieve the last prices for a provided index
   *
   * @param masterdataId Masterdataid of the index to get the latest RT prices for
   * @param variationId Id of the variation for the index
   * @returns List of latest RT prices for index
   */
  getRTVariations(masterdataId: number, variationId: number): Observable<IndexStateModel> {
    return this.http.get<IndexStateModel>(`/realtime/index-state/${masterdataId}/variation/${variationId}`);
  }

  /**
   * Retrieve a list of statements for execution
   *
   * @returns List of available statements
   */
  getExecutorStatements(): Observable<ExecutorStatement[]> {
    return this.http.get<ExecutorStatement[]>('/statement');
  }

  /**
   * Retrieve the result of a statement execution
   *
   * @returns List of rows and columns as result of execution
   */
  getExecutorResult(executorBody: { statementId: number; parameters: Dictionary<string> }): Observable<ExecutorAPIModel[]> {
    return this.http.post<ExecutorAPIModel[]>('/statement', executorBody);
  }

  /**
   * Retrieve a list of possible return types for CAs
   *
   * @returns List of CA return types
   */
  getReturnTypes(): Observable<ReturnTypeModel[]> {
    return this.http.get<ReturnTypeModel[]>('/return-type');
  }

  /**
   * Get Corporate Actions by Type
   *
   * @param providerType providerType custom/master
   * @param isins List of isins to filter CAs
   * @param corporateActionTypes List of corporateActionTypes to filter CAs
   * @param effectiveDate effectiveDate to filter CAs
   */
  corporateActionsOverviewSearch(providerType: string, isins: string[], corporateActionTypes: string[], effectiveDate: Date | Dayjs): Observable<CorporateActionModel[]> {
    let params = new HttpParams();
    params = params.set('isin', isins.join(', '));
    if (effectiveDate) {
      params = params.set('effectiveDate', utc(effectiveDate).format('YYYY-MM-DD'));
    }
    params = params.set('corporateActionType', corporateActionTypes.join(', '));
    return this.http.get<CorporateActionModel[]>(`/corporate-actions/overview/search/${providerType}`, { params });
  }

  /**
   * Get List of Matching Status for the given dates and status
   *
   * @param fromDate Effective Date from
   * @param toDate Effective Date till
   * @param status Status which we want to retrieve, values can be FILTERED, MATCHED, MANUALLY_MATCHED, NOT_MATCHED
   */
  getMatchingStatus(fromDate: Date | Dayjs, toDate: Date | Dayjs, status: string[]): Observable<MatchingStatusModel[]> {
    const params = new HttpParams().set('fromDate', utc(fromDate).format('YYYY-MM-DD')).set('toDate', utc(toDate).format('YYYY-MM-DD')).set('status', status.join(', '));
    return this.http.get<MatchingStatusModel[]>('/corporate-actions/matching-status/effective', { params });
  }

  /**
   * This Method to save matching status object.
   * @param matchingStatus Matching Status that is to be saved
   */
  toggleMatchingStatusReviewRequired(matchingStatus: MatchingStatusModel): Observable<MatchingStatusModel> {
    return this.http.post<MatchingStatusModel>('/corporate-actions/matching-status/', matchingStatus);
  }
}
