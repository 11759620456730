import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpApiInterceptor } from './core/interceptors/http-api.interceptor';
import { PermissionControlPipe } from './core/permission-control/permission-control.pipe';

import { DashboardComponent } from './dashboard/dashboard.component';
import { FallbackComponent } from './fallback/fallback.component';
import { ManageIndexActionsComponent, ManageIndexComponent, ManageIndexConfigurationComponent } from './index-administration';
import { ManageIndexActionsValidationModalComponent } from './index-administration/manage-index-actions-validation-modal/manage-index-actions-validation-modal.component';
import { ManageIndexConfigurationDetailModalComponent } from './index-administration/manage-index-configuration-detail-modal/manage-index-configuration-detail-modal.component';

import { LoginComponent } from './login/login.component';
import { EditSecurityModalComponent } from './securities/edit-security-modal/edit-security-modal.component';
import { SecuritiesComponent } from './securities/securities.component';
import { SecurityListingModalComponent } from './securities/security-listing-modal/security-listing-modal.component';

import { SharedModule } from './share/components/shared.module';
import { OneOfManyRequiredDirective } from './share/directive/one-of-many-required.directive';

import { StateModule } from './state/state.module';

class TranslateLocalLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<unknown> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ManageIndexConfigurationDetailModalComponent,
    DashboardComponent,
    EditSecurityModalComponent,
    FallbackComponent,
    LoginComponent,
    ManageIndexComponent,
    ManageIndexActionsComponent,
    ManageIndexConfigurationComponent,
    ManageIndexActionsValidationModalComponent,
    OneOfManyRequiredDirective,
    SecuritiesComponent,
    SecurityListingModalComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    StateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLocalLoader,
      },
      defaultLanguage: 'en',
    }),
  ],
  providers: [
    { provide: 'StreamOptions', useValue: environment.streamOptions },
    { provide: 'ApiUrl', useValue: environment.apiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: HttpApiInterceptor, multi: true },
    PermissionControlPipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
