// import { Client } from './user.model';

export class Login {
  static readonly type = '[User] user login';

  constructor(public user: string, public password: string) {}
}

export class CookieLogin {
  static readonly type = '[User] cookie login';

  constructor(public token: string) {}
}

export class LoginSuccess {
  static readonly type = '[User] user login success';
}

export class Logout {
  static readonly type = '[User] user logout';
}

export class SelectUser {
  static readonly type = '[User] select user';

  constructor(public selectedClient: number | string) {}
}

export class SelectedUserChanged {
  static readonly type = '[User] selected user changed';
}

export class LoadPermissionMap {
  static readonly type = '[User] load permission map';
}
