import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserState } from '../state/user/user.state';
import { CookieLogin, Login } from '../state/user/user.actions';
import { getCookieValue } from '../share/utils/cookie.util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Select(UserState.permissionLoaded) permissionLoaded$: Observable<boolean>;

  destroy$ = new Subject<void>();

  @Select(UserState.error) errors$: Observable<string>;

  constructor(private store: Store, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.permissionLoaded$.pipe(takeUntil(this.destroy$)).subscribe(async permissionLoaded => {
      if (permissionLoaded) {
        const url = this.route.snapshot.queryParams.url;
        await this.router.navigate([url ? url : '/']);
      }
    });

    const token = getCookieValue('mist-sso-token');
    if (token) {
      this.store.dispatch(new CookieLogin(token));
    }
  }

  async callLogin(user: string, password: string) {
    try {
      await lastValueFrom(this.store.dispatch(new Login(user, password)));
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
